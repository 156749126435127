// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-spin :spinning="loading">
    <!-- Modal for the PROXY Api -->
    <a-modal
      v-model:visible="showEditModal"
      :title="$t('label.proxy.edit.settings')"
      :closable="true"
      :footer="null"
      centered>
      <a-form
        v-ctrl-enter="submitEditModal"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitEditModal"
        layout="vertical"
      >
        <a-form-item ref="domain" name="domain" :label="$t('label.domain')">
          <a-input v-model:value="form.domain" autoFocus>
          </a-input>
        </a-form-item>
        <a-form-item ref="proxynetwork" name="proxynetwork" :label="$t('label.network')">
          <a-select v-model:value="form.proxynetwork">
            <a-select-option value="172.30.28.0/22">172.30.28.0/22</a-select-option>
            <a-select-option value="10.254.228.0/22">10.254.228.0/22</a-select-option>
            <a-select-option value="192.168.252.0/22">192.168.252.0/22</a-select-option>
          </a-select>
        </a-form-item>
        <div style="text-align: right;">
          <a-button :loading="loading" style="margin-right: 4px" @click="closeEditModal">{{ this.$t('label.cancel') }}</a-button>
          <a-button :loading="loading" type="primary" @click="submitEditModal">{{ this.$t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-modal>
    <!-- Component Content -->
    <resource-layout>
      <template #left>
        <slot name="info-card">
          <info-card
            v-if="resource"
            :resource="resource"
            :loading="loading"
            @emit-open-edit-modal="openEditModal()"/>
        </slot>
      </template>
        <template #right>
          <a-card>
            <a-table
              size="small"
              style="overflow: auto; max-height: 25em;"
              :columns="columns"
              :dataSource="items"
              :rowKey="record => record.vmid"
              :pagination="false"
            >
              <template #name="{ record }">
                <router-link v-if="$store.getters.userInfo.roletype !== 'User'" :to="{ path: '/vm/' + record.vmid }">{{ record.name }}</router-link>
              </template>
              <template #networkname="{ record }">
                <router-link v-if="$store.getters.userInfo.roletype !== 'User'" :to="{ path: '/guestnetwork/' + record.layer2id }">{{ record.networkname }}</router-link>
              </template>
              <template #actions="{ record }">
                <div style="display: flex; justify-content: center; align-items: center;">
                  <div class="actions">
                    <a
                      :href="'https://' + resource.domain + '/' + record.name"
                      target="_blank">
                      <tooltip-button
                        tooltipPlacement="top"
                        tooltip="Proxy VM URL"
                        type="default"
                        icon="linkOutlined" />
                    </a>
                  </div>
                  <div class="actions" style="margin-left: 6px;">
                    <tooltip-button
                      tooltipPlacement="top"
                      :tooltip="$t('label.proxy.delete.client')"
                      icon="deleteOutlined"
                      type="primary"
                      danger
                      @click="deleteProxyClient(record)" />
                    Delete
                  </div>
                </div>
              </template>
            </a-table>
          </a-card>
          <a-card>
            <a-button type="default" shape="round" @click="fetchProxyLogs"><ReloadOutlined></ReloadOutlined> Reload Logs</a-button>
            <a-tabs v-model="activeKey">
              <a-tab-pane key="proxy" tab="Domain/SSL Log">
                <!-- <h5 v-for="logLine in proxyLogs" :key="logLine">{{ '[' + logLine.created_at + '] ' + logLine.status }}</h5> -->
                <a-table
                  size="small"
                  style="overflow: auto; max-height: 40em;"
                  :columns="logColumns"
                  :dataSource="proxyLogs"
                  :rowKey="record => record.index"
                  :pagination="false"
                >
                </a-table>
              </a-tab-pane>
              <!-- <a-tab-pane key="logs" tab="LOGS (mockData)"> <h5 v-for="index in 10" :key="index">{{ '[' + mockData.myDate + ']' + mockData.msg }}</h5></a-tab-pane>
              <a-tab-pane key="test" tab="Tab 2 (mockData)"><h5 v-for="index in 10" :key="index">{{ '[' + mockData.myDate + ']' + mockData.msg }}</h5></a-tab-pane> -->
            </a-tabs>
          </a-card>
        </template>
    </resource-layout>
    <!--
      <a-modal
        v-model="exampleModalBoolean"
        :maskClosable="false"
        :footer="null"
        @cancel="accountModal = false">
        <div>
          <div style="margin-bottom: 10px;">
            <div class="list__label">{{ $t('label.account') }}</div>
            <div>{{ selectedItem.account }}</div>
          </div>
          <div style="margin-bottom: 10px;">
            <div class="list__label">{{ $t('label.domain') }}</div>
            <div>{{ selectedItem.domain }}</div>
          </div>
          <div style="margin-bottom: 10px;">
            <div class="list__label">{{ $t('label.system.vms') }}</div>
            <div>{{ selectedItem.forsystemvms }}</div>
          </div>
        </div>

        <div :span="24" class="action-button">
          <a-button @click="accountModal = false">{{ $t('label.close') }}</a-button>
        </div>
      </a-modal> -->
  </a-spin>
</template>

<script>
import { api } from '@/api'
import api2 from '@/wpApi/api2'
import TooltipButton from '@/components/widgets/TooltipButton'
import InfoCard from '@/components/view/InfoCard'
import ResourceLayout from '@/layouts/ResourceLayout'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'ProxySettings',
  components: {
    TooltipButton,
    InfoCard,
    ResourceLayout
  },
  data () {
    return {
      resource: {},
      proxyDomainRegex: /^[a-zA-Z0-9.-]*$/,
      tempResource: {},
      proxyLogs: [],
      activeKey: 'proxy',
      exampleModalBoolean: false,
      vpcOwnerAccUuid: String,
      showEditModal: false,
      items: [],
      tempItems: [],
      loading: false,
      columns: [
        {
          title: 'VM ' + this.$t('label.name'),
          dataIndex: 'name',
          slots: { customRender: 'name' }
        },
        {
          title: this.$t('label.guestnetwork'),
          dataIndex: 'networkname',
          slots: { customRender: 'networkname' }
        },
        {
          title: this.$t('label.created'),
          dataIndex: 'created_at'
        },
        {
          title: this.$t('label.action'),
          dataIndex: 'actions',
          slots: { customRender: 'actions' }
        }
      ],
      logColumns: [
        {
          title: this.$t('label.status'),
          dataIndex: 'status'
        },
        {
          title: this.$t('label.created'),
          dataIndex: 'created_at',
          width: '12em'
        }
      ]
    }
  },
  created () {
    this.initForm()
  },
  async mounted () {
    await this.fetchData()
  },
  watch: {
    /* network (newItem, oldItem) {
      if (!newItem || !newItem.id) {
        return
      }
      this.fetchData()
    } */
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        domain: this.resource.domain || undefined,
        proxynetwork: this.resource.subnet || undefined
      })
      this.rules = reactive({
        domain: [{ required: true, message: `${this.$t('label.required')}` }, { validator: this.validateProxyDomain }],
        proxynetwork: [{ required: true, message: `${this.$t('label.required')}` }]
      })
    },
    async fetchData () {
      // fetchData to get the proxy Data
      await this.getVpcOwnerAccId()
      await this.fetchProxyData()
      await this.fetchProxyClients()
      await this.fetchProxyLogs()
    },
    async fetchProxyLogs () {
      var params = new FormData()
      params.append('proxyid', this.$route.params.proxyid)
      await api2.getDomainInfo(params).then(response => {
      // this.proxyLogs = [...[response.data][0]].reverse()
        this.proxyLogs = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async fetchProxyData () {
      this.loading = true
      var getProxyparams = new FormData()
      getProxyparams.append('accountid', this.vpcOwnerAccUuid)
      getProxyparams.append('vpcid', this.$route.params.id)
      await api2.getProxySettings(getProxyparams).then(response => {
        this.tempResource = response.data
        this.loading = false
      }).catch(error => {
        this.$notifyError(error)
        this.loading = false
      })
      if (this.tempResource.status !== 'error') {
        await api('listAccounts', { id: this.tempResource.accountid }).then(response => {
          this.tempResource.accountname = response.listaccountsresponse.account[0].name
        }).catch(error => {
          console.log(error)
        })
        await api('listVPCs', { id: this.tempResource.vpcid }).then(response => {
          this.tempResource.vpcname = response.listvpcsresponse.vpc[0].name
        }).catch(error => {
          console.log(error)
        })
        await api('listVirtualMachines', { id: this.tempResource.vmid }).then(response => {
          this.tempResource.vmname = response.listvirtualmachinesresponse.virtualmachine[0].name
        }).catch(error => {
          console.log(error)
        })
        this.resource = this.tempResource
        this.loading = false
      }
    },
    async fetchProxyClients () {
      this.loading = true
      var proxyClientParams = new FormData()
      proxyClientParams.append('accountid', this.vpcOwnerAccUuid)
      proxyClientParams.append('vpcid', this.$route.params.id)
      proxyClientParams.append('proxyid', this.$route.params.proxyid)
      await api2.getProxyClients(proxyClientParams).then(response => {
        this.tempItems = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
      if (this.tempItems.message !== 'error') {
        for (var clientVM of this.tempItems) {
          await api('listVirtualMachines', { id: clientVM.vmid }).then(response => {
            clientVM.name = response.listvirtualmachinesresponse.virtualmachine[0].name
          }).catch(error => {
            console.log(error)
          })
          await api('listNetworks', { listall: true, id: clientVM.layer2id }).then(response => {
            clientVM.networkname = response.listnetworksresponse.network[0].name
          }).catch(error => {
            console.log(error)
          })
        }
      }
      this.items = this.tempItems
      this.loading = false
    },
    async deleteProxyClient (vm) {
      var params = new FormData()
      params.append('virtualmachineid', vm.vmid)
      params.append('proxyid', this.$route.params.proxyid)
      await api2.delProxyClient(params).then(response => {
        if (response.data.status === 'ok') {
          this.fetchProxyClients()
        }
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async getVpcOwnerAccId () {
      await api('listAccounts', { listall: true, name: this.$route.params.name }).then(response => {
        var responseAccUuid = response.listaccountsresponse.account.filter(account => account.name === this.$route.params.name)[0].id
        this.vpcOwnerAccUuid = responseAccUuid !== undefined ? responseAccUuid : ''
      }).catch(error => {
        this.$notifyError(error)
        console.log(error)
      })
    },
    submitEditModal () {
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)
        this.loading = true
        var params = new FormData()
        params.append('proxyid', this.$route.params.proxyid)
        params.append('subnet', values.proxynetwork)
        params.append('domain', values.domain.toLowerCase())
        await api2.setProxySettings(params).then(response => {
          if (response.data.status === 'ok') {
            this.loading = false
            this.fetchProxyData()
            this.showEditModal = false
          }
        }).catch(error => {
          this.$notifyError(error)
          console.log(error)
          this.loading = false
          this.showEditModal = false
        })
      })
    },
    validateProxyDomain (rule, value) {
      const errorMessage = this.$t('message.validate.special.characters.proxyDomain')
      if (this.proxyDomainRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    openEditModal () {
      this.showEditModal = true
    },
    closeEditModal () {
      this.showEditModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    &__item {
      display: flex;
    }

    &__data {
      display: flex;
      flex-wrap: wrap;
    }

    &__col {
      flex-basis: calc((100% / 3) - 20px);
      margin-right: 20px;
      margin-bottom: 10px;
    }

    &__label {
      font-weight: bold;
    }
  }

  .ant-list-item {
    padding-top: 0;
    padding-bottom: 0;

    &:not(:first-child) {
      padding-top: 20px;
    }

    &:not(:last-child) {
      padding-bottom: 20px;
    }
  }

  .actions {
    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .ant-select {
    width: 100%;
  }

  .form {
    .actions {
      display: flex;
      justify-content: flex-end;

      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

    }
  }

  .pagination {
    margin-top: 20px;
  }
</style>
